.voucher-body{
  font-family: Arial, sans-serif;
  font-size: small; 
  color: #000;
  line-height: 1;
  margin: 10px;
}

.voucher-body table { width: 100%; border-collapse: collapse; }
.voucher-body th, .voucher-body td { border: 1px solid black; padding: 2px; text-align: center; }
.voucher-body .no-border { border: none; }
.voucher-body .center-text { text-align: center; font-weight: bold; }
.voucher-body strong { font-weight: bold;}